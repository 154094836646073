const s = 1000;
const min = s * 60;
const hour = min * 60;
const day = 24 * hour;
const week = 7 * day;

export const startOfWeekMon = (d) => {
    d = new Date(d);
    d.setHours(0, 0, 0, 0);
    var day = d.getDay();
    day = day == 0 ? 7 : day;
    var diff = d.getDate() - (day - 1);
    var g = new Date(d.setDate(diff))
    return g;
}


export const startOfWeek = (d) => {
    d = new Date(d);
    d.setHours(0, 0, 0, 0);
    var day = d.getDay();
    day = day == 6 ? -1 : day;
    var diff = d.getDate() - (day + 1);
    var g = new Date(d.setDate(diff))
    return g;
}

//test if d is a date
export const isDate = (d) => {
    return d instanceof Date && !isNaN(d);
}


export const endOfWeekSun = (d) => {
    d = new Date(d);
    d.setHours(23, 59, 59, 999);
    var day = d.getDay();
    day = day == 0 ? 7 : day;
    var diff = d.getDate() + (7-day);
    var g = new Date(d.setDate(diff))
    return g;
}
export const endOfWeek = (d) => {
    d = new Date(d);
    d.setHours(23, 59, 59, 999);
    var day = d.getDay();
    day = day == 6 ? -1 : day;
    var diff = d.getDate() - (day + 1) + 6;
    var g = new Date(d.setDate(diff))
    return g;
}
export const startOfYear = (d) => {
    d = new Date(d);
    d.setHours(0, 0, 0, 0);
    d.setDate(1);
    d.setMonth(0);
    return new Date(d);
}

export const endOfYear = (d) => {
    d = new Date(d);
    d.setHours(23, 59, 59, 999);
    d.setMonth(11);
    d.setDate(31);
    return new Date(d);
}

export const startOfFinancialYear = (d) => {
    d = new Date(d);
    d.setHours(0, 0, 0, 0);
    d.setDate(1);
    if (d.getMonth() < 6) {
        d.setFullYear(d.getFullYear() - 1);
    }
    d.setMonth(6);
    return new Date(d);
}
export const endOfFinancialYear = (d) => {
    d = new Date(d);
    d.setHours(23, 59, 59, 999);
    if (d.getMonth() > 5) {
        d.setFullYear(d.getFullYear() + 1);
    }
    d.setMonth(5);
    d.setDate(30);
    return new Date(d);
}
export const startOfMonth = (d) => {
    d = new Date(d);
    d.setHours(0, 0, 0, 0);
    d.setDate(1);
    return new Date(d);
}

export const endOfMonth = (d) => {
    d = new Date(d);
    d.setHours(23, 59, 59, 999);
    d.setDate(15);
    d.setMonth(d.getMonth() + 1);
    d.setDate(0);
    return new Date(d);
}
export const startOfNextMonth = (d) => {
    d = new Date(d);
    d.setHours(23, 59, 59, 999);
    d.setMonth(d.getMonth() + 1);
    d.setDate(1);
    return new Date(d);
}
export const shiftWeekMonToSun = (d, weeks) => {
    d.setHours(0, 0, 0, 0);
    var currStart = startOfWeekMon(new Date(d));
    var newTime = currStart.getTime() + (week * weeks);
    var start = new Date(newTime);
    if (start.getHours() > 1) {  //daylight saving error check
        start = shiftHours(start, 1);
    }
    var end = (endOfWeekSun(new Date(start)));
    return { start: (start), end };
}
export const shiftWeek = (d, weeks) => {
    d.setHours(0, 0, 0, 0);
    var currStart = startOfWeek(new Date(d));
    var newTime = currStart.getTime() + (week * weeks);
    var start = new Date(newTime);
    if (start.getHours() > 1) {  //daylight saving error check
        start = shiftHours(start, 1);
    }
    var end = tomorrow(endOfWeek(new Date(start)));

    return { start, end };
}

export const shiftHours = (d, hours) => {
    var diff = d.getHours() + (hours);
    var newDate = new Date(d.setHours(diff));
    return newDate;
}
export const shiftMins = (d, mins) => {
    var diff = d.getMinutes() + (mins);
    var newDate = new Date(d.setMinutes(diff));
    return newDate;
}
export const shiftMonth = (d, mons) => {
    var diff = d.getMonth() + (mons);
    var newDate = new Date(d.setMonth(diff));
    return newDate;
}
export const shiftHoursMins = (d, hours, mins) => {
    var diff = d.getMinutes() + (mins);
    var hourDate = shiftHours(d, hours);
    var newDate = shiftMins(hourDate, mins);
    return newDate;
}
export const dateFormat = (d) => {
    d = new Date(d);
    return d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear();
}
export const dateFormatZeros = (d) => {
    d = new Date(d);
    var day = d.getDate();
    var month = d.getMonth() + 1;
    var year = d.getFullYear();
    return (day < 10 ? ('0' + day) : day) + '/' + (month < 10 ? ('0' + month) : month) + '/' + year
}

export const dateTimeFormat = (d) => {
    d = new Date(d);
    var ampm = d.getHours() > 11 ? 'pm' : 'am';
    var hours = d.getHours() > 12 ? (d.getHours() - 12) : d.getHours();
    return d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear() + ' ' + (hours < 10 ? ('0' + hours) : hours) + ':' + (d.getMinutes() < 10 ? ('0' + d.getMinutes()) : d.getMinutes()) + " " + ampm;
}
export const shortFormat = (d) => {
    d = new Date(d);
    var ampm = d.getHours() > 11 ? 'pm' : 'am';
    var hours = d.getHours() > 12 ? (d.getHours() - 12) : d.getHours();
    return d.getDate() + '/' + (d.getMonth() + 1) + ' ' + (hours < 10 ? ('0' + hours) : hours) + ':' + (d.getMinutes() < 10 ? ('0' + d.getMinutes()) : d.getMinutes()) + " " + ampm;
}
export const textfieldDateFormat = (d) => {

    return d.getFullYear() + '-' + ((d.getMonth() + 1) < 10 ? '0' + String((d.getMonth() + 1)) : (d.getMonth() + 1)) + '-' + (d.getDate() < 10 ? ('0' + String(d.getDate())) : d.getDate());
}

export const startOfDay = (d) => {
    d = new Date(d);
    d.setHours(0, 0, 0, 0);
    return new Date(d);
}
export const startGMTOfDay = (d) => { //not right always only when plus 11 not whne plus 10, goof
    d = new Date(d);
    d.setHours(11, 0, 0, 0);
    return new Date(d);
}
export const endOfDay = (d) => {
    d = new Date(d);
    d.setHours(23, 59, 59, 999);
    return new Date(d);
}

export const tomorrow = (d) => {
    d = new Date(d);
    d.setHours(0, 0, 0, 0);
    var diff = d.getDate() + (1);
    var start = new Date(d.setDate(diff));
    return new Date(start);
}

export const yesterday = (d) => {
    d = new Date(d);
    d.setHours(0, 0, 0, 0);
    var diff = d.getDate() - (1);
    var start = new Date(d.setDate(diff));
    return new Date(start);
}
export const dateToZero = (d) => {
    d = new Date(d);
    d.setHours(0, 0, 0, 0);
    return new Date(d);
}
export const DayDateMon = (d) => {
    d = new Date(d);
    return <div><div>{d.toLocaleString('en-us', { weekday: 'long' })}</div><div>{d.getDate() + ' ' + d.toLocaleString('en-us', { month: 'short' })}</div></div>;
}
export const generateTextDays = (start, end) => {
    var days = [];
    var sDay = new Date(start);
    while (sDay < new Date(end)) {
        days.push(<div><div>{sDay.toLocaleString('en-us', { weekday: 'long' })} </div><div>{sDay.getDate() + ' ' + sDay.toLocaleString('en-us', { month: 'short' })}</div></div>);
        sDay = tomorrow(sDay);
    }
    return days;
}


export const generateDates = (start, end) => {
    var days = [];
    var sDay = new Date(start);
    while (sDay < new Date(end)) {
        days.push(sDay);
        sDay = tomorrow(sDay);
    }
    return days;
}

export const dayCompare = (dateA, dateB) => {
    var A = new Date(dateA);
    var B = new Date(dateB);
    return ((A.getDate() === B.getDate()) && (A.getMonth() === B.getMonth()) && (A.getFullYear() === B.getFullYear()));
}

export const undoDateFormat = (textDate) => {
    let n = textDate.match(/([0-9]+)/gi);
    if (n) {
        return new Date(Number(n[2].length < 4 ? ('20' + n[2]) : n[2]), (Number(n[1]) - 1), Number(n[0]));
    } else {
        return new Date();
    }
}


export const undoLongDateFormat = (textDate) => {
    let n = textDate.match(/([0-9]+)/gi);
    if (n) {
        var hour = Number(n[3]);
        if (textDate.includes('pm')) {
            if (hour !== 12) {
                hour = hour + 12
            }
        }
        return new Date(Number(n[2]), (Number(n[1]) - 1), Number(n[0]), hour, n[4]);
    } else {
        return new Date();
    }
}

export const isWithinMinDifference = (dateA, mins, dateB) => {
    var timeA = dateA.getTime();
    var timeB = dateB.getTime();
    if ((timeA + mins * 1000 * 60) < timeB) {
        return false;
    } else {
        return true;
    }
}

export const AmountOfTimeToText = (time) => {
    // hr min sec
    var text = '';
    if (time > 3600) {
        var hr = Math.floor(time / 3600);
        text = text + hr + ' hr ';
        time = time - (hr * 3600);
    }
    if (time > 60) {
        var min = Math.floor(time / 60);
        text = text + min + ' min ';
        time = time - (min * 60);
    }
    if (time > 0) {
        text = text + time + ' sec ';
    }
    return text;

}

export const adjustDateByStockItem = (date, stockItem) => {
    if (stockItem) {
        if (stockItem.isFrozen) {
            return shiftHours(date, 24 * 365);
        } else if (stockItem.isHalfshell) {
            return shiftHours(date, 24 * 9);
        } else {
            return shiftHours(date, 24 * 14);;
        }
    } else {
        return date;
    }
}