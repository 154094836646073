import React from 'react';
import { dateFormat, textfieldDateFormat, generateTextDays, generateDates, dayCompare } from "../../../utils/dateFunctions";
import { handlePagination } from "../../../utils/apiFunction";
import { numberFormat } from "../../../utils/numberFunctions";
import { useJumboTheme } from "@jumbo/hooks";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";

const POSummaryPoints = (props) => {
    const uri = process.env.REACT_APP_BASE_URL;
    const [loading, setLoading] = React.useState(false);
    const [columns, setColumns] = React.useState([]);
    const [procurement, setProcurement] = React.useState([]);
    const [reload, setReload] = React.useState(true);

    React.useEffect(() => {
        setProcurement([]);
        console.log(props.location)
        getProcurementData(props.location, textfieldDateFormat(props.startDate), textfieldDateFormat(props.endDate));
    }, [props.location, props.startDate, props.endDate, reload, props.externalReload]);

    var getProcurementData = async (location, startDate, endDate) => {
        console.log(location)
        setLoading(true);
        var data = [];

        data = await handlePagination(fetch, uri + ('/purchaseorder/Get'), startDate, endDate, { location, UsePromisedDate: true });

        //filter out cancelled status orders   
        var newData = data.filter((po) => {
            var keep = true;
            if (props.warehouseFilter) {
                if (po.purchaseOrderLines?.[0]?.warehouse?.description !== props.warehouseFilter) {
                    keep = false;
                }
            }
            if (po.status === 'Canceled') {
                keep = false;
            }
            return keep;
        })
        console.log(newData)
        setLoading(false);
        setProcurement(newData);
    }
    const ifNANisZero = (num) => {
        if (isNaN(num)) {
            return 0;
        } else {
            return num;
        }
    }

    const filterByName = (data, name, allTotal) => {
        var total = 0;
        data.map((po) => {
            if (po.supplier?.name === name) {
                total += po.purchaseOrderLines.reduce((p, c) => {
                    return p + c.ordered;
                }, 0);
            }
        });
        return numberFormat(total) + ' (' + ifNANisZero((total / allTotal * 100).toFixed(1)) + '%)';
    }
    const filterByIntExt = (data, int) => {
        var total = 0;
        data.map((po) => {
            if (po.supplier?.name.includes('(Internal)') === int) {
                total += po.purchaseOrderLines.reduce((p, c) => {
                    return p + c.ordered;
                }, 0);
            }
        });
        return (total);
    }
    var internal = filterByIntExt(procurement, true);
    var external = filterByIntExt(procurement, false);
    var totalIntExt = internal + external;
    return (<Grid container spacing={2} mb={1}>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} xxl={3}>
            <Grid container spacing={2} mb={1}>
                <Grid item xs={12}>
                    <StatCardMin title={"Internal"} value={numberFormat(internal) + ' (' + ifNANisZero((internal / totalIntExt * 100).toFixed(1)) + '%)'} />
                </Grid>
                <Grid item xs={12}>
                    <StatCardMin title={"External"} value={numberFormat(external) + ' (' + ifNANisZero((external / totalIntExt * 100).toFixed(1)) + '%)'} />
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} xxl={3}>
            <Grid container spacing={2} mb={1}>
                <Grid item xs={12}>
                    <StatCardMin title={"AOC Merimbula"} value={filterByName(procurement, 'AOC Merimbula Farm (Internal)', totalIntExt)} />
                </Grid>
                <Grid item xs={12}>
                    <StatCardMin title={"AOC Wapengo"} value={filterByName(procurement, 'AOC Wapengo Farm (Internal)', totalIntExt)} />
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} xxl={3}>
            <Grid container spacing={2} mb={1}>
                <Grid item xs={12}>
                    <StatCardMin title={"AOC Narooma"} value={filterByName(procurement, 'AOC Narooma Wagonga Farm (Internal)', totalIntExt)} />
                </Grid>
                <Grid item xs={12}>
                    <StatCardMin title={"AOC Shoalhaven"} value={filterByName(procurement, 'AOC Shoalhaven Farm (Internal)', totalIntExt)} />
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} xxl={3} >
            <Grid container spacing={2} mb={1}>
                <Grid item xs={12}>
                    <StatCardMin title={"AOC Wallis Lake"} value={filterByName(procurement, 'AOC Wallis Lake Farm (Internal)', totalIntExt)} />
                </Grid>

            </Grid>
        </Grid>
    </Grid>)

}

export default POSummaryPoints;


const StatCardMin = (props) => {
    var bgroundStyle = {};
    if (props.color) {
        bgroundStyle = {
            background: props.color,
        }
    }
    return <Card sx={{ position: "relative", ...bgroundStyle }} >
        <CardHeader
            title={<React.Fragment><Typography style={{}} variant={"h5"} mb={0}>{props.title} </Typography><Typography style={{ right: '10px', top: '15px', position: 'absolute' }} variant={"h2"}>{props.value}</Typography></React.Fragment>}
            subheader={''}
            action={''}
            avatar={''}
            sx={{
                zIndex: 2,
                position: "relative",
                //...headerSx
            }}
        />
    </Card>
}