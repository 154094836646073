import React from 'react';
import Grid from "@mui/material/Grid";
import { startOfWeek, endOfWeek, tomorrow, yesterday, dateFormat, textfieldDateFormat } from "../../../utils/dateFunctions";
import { postApi } from "../../../utils/apiFunction";
import { useSearchParams } from "react-router-dom";
import { ACCESS, ACCESS_SECTIONS } from '../../../utils/constants/access';
import FormModal from '../../../components/FormModal';
import { Button } from '@mui/material';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { dataURItoBlob } from '../../../utils/imageFunctions';
import Link from "@mui/material/Link";
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Div from "@jumbo/shared/Div";
import { getAPIDataParams, getAPIData, getAPIDataParamsReturn } from '../../../utils/apiFunction';
import Typography from "@mui/material/Typography";
import SelectAOCFarmWarehouse from '../../../components/SelectAOCFarmWarehouse';
import SelectWarehouse from "../../../components/SelectWarehouse";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { CircularProgress } from '@mui/material';
import DatePicker from "../../../shared/DatePicker";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import Box from "@mui/material/Box";
import { useJumboTheme } from "@jumbo/hooks";
import { checkAccess } from '../../../utils/roleFunctions';
import SubmitButtonDisplayer from "../../../components/SubmitButtonDisplayer";
import ControlledTextField from "../../../components/ControlledTextField";
import SelectTextField from "../../../components/SelectTextField";
import IconButton from '@mui/material/IconButton';
import ReplayIcon from '@mui/icons-material/Replay';
import AddIcon from '@mui/icons-material/Add';
import Stack from '@mui/material/Stack';
import RemoveIcon from '@mui/icons-material/Remove';
import { displayDozFormat, numberFormat } from '../../../utils/numberFunctions';
import OTRPdf from '../../../components/Transfers/CreateOTRPDFfromGroup';
import OTRLabels from '../../../components/Transfers/CreateOTRLabelsfromGroup';
import HistoryModal from './HistoryModal';
import UndoModal from './UndoModal';
import { AccountTree } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { textColorContrast } from "../../../utils/renderFunctions";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Camera from 'react-html5-camera-photo';
import DropZone from "../../../components/DropZone";
import { adalApiFetch } from '../../../../adalConfig';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { modifyTotalOysters } from '../utils/onShoreFunction';
import OnShoreCard from './OnShoreCard';
const READY = 'Ready';
const PICKEDUP = 'PickedUp';
const RECEIVED = 'Received';
const CANCELLED = 'Cancelled';





const OnShoreDisplay = (props) => {
    const [selected, setSelected] = React.useState(null);
    const [selectedTransfer, setSelectedTransfer] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [reload, setReload] = React.useState(true);
    const [groups, setGroups] = React.useState([]);
    const [cropCols, setCropCols] = React.useState([]);
    const [openModal, setOpenModal] = React.useState(false);
    const [openUndo, setOpenUndo] = React.useState(false);
    const [openHistory, setOpenHistory] = React.useState(false);
    const [selGroupId, setSelGroupId] = React.useState(null);
    const [prevSortOption, setPrevSortOption] = React.useState(props.sortOption);
    const [combining, setCombining] = React.useState(false);
    const [combineGroups, setCombineGroups] = React.useState([]);
    const [modalForm, setModalForm] = React.useState('');
    const [modalTitle, setModalTitle] = React.useState('');
    const requiredEditAccess = { key: ACCESS_SECTIONS.OYSTERMATE, value: ACCESS.EDIT };




    const sortGroups = (grps) => {
        var sortedGroups = [...grps];
        sortedGroups.sort((a, b) => {
            if (props.sortOption === 0) {
                return new Date(a.shore?.date) > new Date(b.shore?.date) ? 1 : -1
            } else if (props.sortOption === 1) {
                return new Date(a.shore?.created) < new Date(b.shore?.created) ? 1 : -1
            }
        });
        setGroups(sortedGroups);
    }
    if (prevSortOption !== props.sortOption) {
        setPrevSortOption(props.sortOption);
        sortGroups(groups);
    }

    React.useEffect(() => {


        const getFarmData = async () => {
            setLoading(true);
            if (props.farm) {
                await getAPIDataParams('/Farms/Groups/OnShore', sortGroups, { WarehouseId: props.farm?.warehouseId });
            }
            setLoading(false);
        }
        getFarmData();
    }, [reload, props.farm, props.reload])
    //console.log(groups)
    React.useEffect(() => {
        const getCC = async () => {
            await getAPIData('/Farms/Lookups/BatchColours', setCropCols);
        };
        getCC();
    }, []);

    const reloadFunc = () => {
        setReload(!reload);
    }
    const select = (ind) => (e) => {
        e.stopPropagation();
        props.setBatch(groups[ind]);
        props.handleClose();
    }
    const combine = (ind) => async (e) => {
        e.stopPropagation();
        //setOpenModal(true);
        //setModalForm(<Combine cropCols={cropCols} access={props.access} handleClose={handleCloseModal} groupA={groups[selected]} groupB={groups[ind]} />)
        setCombining(true);
        //check group doesnt already exist in combineGroups
        var alreadyExists = combineGroups.find((grp) => {
            return grp.id === groups[ind].id;
        })
        if (!alreadyExists) {
            if (combineGroups.length === 0) {
                setCombineGroups([groups[selected], groups[ind]]);
            } else {
                if (combineGroups[0].id !== groups[selected].id) {
                    setCombineGroups([groups[selected], groups[ind]]);
                } else {
                    setCombineGroups([...combineGroups, groups[ind]]);
                }
            }
        }
    }
    const transfer = (ind) => async (e) => {
        e.stopPropagation();
        var cpyTrans = [...selectedTransfer];
        cpyTrans.push(ind);
        console.log(cpyTrans)
        setSelectedTransfer(cpyTrans);
    }
    const createTransfer = (inds) => async (e) => {
        e.stopPropagation();
        setModalForm(<Transfer cropCols={cropCols} access={props.access} handleClose={handleCloseModal} groups={inds.map((ind) => { return groups[ind]; })} warehouseId={props.farm?.warehouseId} />)
        setOpenModal(true);
    }
    const split = (ind) => async (e) => {
        e.stopPropagation();
        setModalForm(<Split cropCols={cropCols} access={props.access} handleClose={handleCloseModal} group={groups[ind]} />)
        setOpenModal(true);


    }
    const grade = (ind) => (e) => {
        e.stopPropagation();
        setModalForm(<Grade cropCols={cropCols} access={props.access} handleClose={handleCloseModal} group={groups[ind]} />)
        setOpenModal(true);

    }
    const deploy = (ind) => (e) => {
        e.stopPropagation();
        setModalForm(<Deploy cropCols={cropCols} access={props.access} handleClose={handleCloseModal} group={groups[ind]} />)
        setOpenModal(true);

    }
    const selectedCard = (ind) => () => {
        if (checkAccess(props.access, requiredEditAccess)) {
            if (selected === ind) {
                setSelected(null);
                setSelectedTransfer([]);
            } else {
                if (ind !== selected) {
                    setSelectedTransfer([]);
                }
                setSelected(ind);
            }
            setCombining(false);
            setCombineGroups([]);
        }
    }
    const handleCloseModal = () => {
        setOpenModal(false);
        reloadFunc();
        setSelected(null);
        setSelectedTransfer([]);
        setCombineGroups([]);
    }
    const openHistoryModal = (ind) => (e) => {
        e.stopPropagation();
        setSelGroupId(groups[ind]?.id);
        setOpenHistory(true);
    }
    const openUndoModal = (ind) => (e) => {
        e.stopPropagation();
        setSelGroupId(groups[ind]?.id);
        setOpenUndo(true);
    }
    const noteEdit = (ind) => (e) => {
        e.stopPropagation();
        setModalForm(<NoteEdit cropCols={cropCols} access={props.access} handleClose={handleCloseModal} group={groups[ind]} />)
        setOpenModal(true);
    }
    const addPhoto = (ind) => (e) => {
        e.stopPropagation();
        setModalForm(<AttachPhoto openHistoryModal={openHistoryModal(ind)} cropCols={cropCols} access={props.access} handleClose={handleCloseModal} group={groups[ind]} />)
        setOpenModal(true);
    }
    return (

        <Grid container spacing={3.5}>
            <FormModal open={openModal} handleClose={handleCloseModal} title={modalTitle} style={{ zIndex: 100000 }}>
                {modalForm}
            </FormModal>
            <HistoryModal open={openHistory} setOpen={setOpenHistory} GroupId={selGroupId} />
            <UndoModal reload={reloadFunc} open={openUndo} setOpen={setOpenUndo} cropCols={cropCols} access={props.access} handleClose={handleCloseModal} GroupId={selGroupId} />
            <Grid item xs={12} >
                {selectedTransfer.length > 0 ? <Card >
                    <CardContent>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={9} lg={10}>
                                {selectedTransfer.map((st) => {

                                    return <Typography variant={"h5"} mb={.5}>{groups[st]?.grade?.description + ': ' + displayDozFormat(groups[st]?.totalUnits * groups[st]?.average, true)}</Typography>
                                })}
                            </Grid>
                            <Grid item xs={12} md={3} lg={2}>
                                <Button onClick={createTransfer(selectedTransfer)} style={{ margin: '5px' }} variant={"contained"}>Create Transfer</Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card> : null}
            </Grid>
            {!loading ? groups.map((grp, ind) => {
                var opacity = 0.35;
                var isSel = false;
                var canCombine = false;
                if ((selected === ind)) {
                    isSel = true;
                }
                if (isSel) {
                    opacity = 1;
                }
                var transSelOn = (selectedTransfer.find((st) => { return st === ind }) >= 0);
                if (transSelOn) {
                    opacity = 1;
                }
                var transferLegal = false;
                if ((grp.unitType.description === "Bins (Transport)") || (grp.unitType.description === "Bags (Transport)")) {
                    transferLegal = true;
                }
                if (!transSelOn && transferLegal) {
                    if (selectedTransfer.length > 0) {
                        //conditions for transfer
                        transferLegal = false;
                        var initTransfer = groups[selectedTransfer[0]];
                        if (initTransfer.shore?.date === grp.shore?.date) {
                            if (initTransfer.previousLocation?.line?.lease?.estuaryId === grp.previousLocation?.line?.lease?.estuaryId) {
                                if (initTransfer.previousLocation?.line?.lease?.harvestAreaId === grp.previousLocation?.line?.lease?.harvestAreaId) {
                                    if (initTransfer.previousLocation?.line?.lease?.id === grp.previousLocation?.line?.lease?.id) {
                                        transferLegal = true;
                                    } else {
                                        if (selectedTransfer.length === 1) {
                                            transferLegal = true;
                                        } else {
                                            selectedTransfer.map((st) => {
                                                if (groups[st].previousLocation?.line?.lease?.id === grp.previousLocation?.line?.lease?.id) {
                                                    transferLegal = true;
                                                }
                                                return null;
                                            })
                                        }
                                    }
                                }
                            }
                        }
                    }

                }
                if ((selected !== null)) {
                    if (grp.crop?.id === groups[selected]?.crop?.id) {
                        if (grp.isHatched === groups[selected].isHatched) {

                            //if (grp.previousLocation?.line?.lease?.id === groups[selected].previousLocation?.line?.lease?.id) {
                            //if (grp.shore?.date === groups[selected].shore?.date) { 
                            //alert if leases are different
                            canCombine = true;
                            //}
                            //}

                        }
                    }

                }
                var incompleteGrade = false;
                if (grp.children && grp.children.length > 0) {
                    incompleteGrade = true
                }
                if (groups[selected]?.children && groups[selected]?.children.length > 0) {
                    canCombine = false;
                }
                if (isSel && combining && (combineGroups.length > 0) && (combineGroups[0].id === grp.id)) {
                    var groupsLen = combineGroups.length;
                    return (<Grid item xs={12} sm={12} md={6 * groupsLen} lg={4 * groupsLen} xl={3 * groupsLen}>
                        <CombineGroup groups={combineGroups} cropCols={cropCols} access={props.access} handleClose={handleCloseModal} />
                    </Grid>);
                } else if (combineGroups.find((cg) => {
                    return cg.id === grp.id;
                })) {
                    return null;
                } else {
                    return (<Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                        <OnShoreCard undoEnabled={props.undoEnabled} access={props.access} openHistoryModal={openHistoryModal(ind)} openUndoModal={openUndoModal(ind)} cropCols={cropCols} select={props.select} isSel={isSel} selectedCard={selectedCard} selected={selected} transSelOn={transSelOn} group={grp} canCombine={canCombine} opacity={opacity} ind={ind} selectFunc={select} combineFunc={combine} gradeFunc={grade} deployFunc={deploy} splitFunc={split} transferFunc={transfer} ableToTransfer={props.ableToTransfer} transSel={selectedTransfer.length > 0} transferLegal={transferLegal} incompleteGrade={incompleteGrade} noteEdit={noteEdit} addPhoto={addPhoto} />
                    </Grid>);
                }
            }) : <CircularProgress />}

        </Grid>
    );
}

export default OnShoreDisplay;







const Deploy = (props) => {



    const [completed, setCompleted] = React.useState(props.completed);
    const [total, setTotal] = React.useState(props.group?.totalUnits);
    const [loading, setLoading] = React.useState(false);
    const [date, setDate] = React.useState(new Date());
    const [error, setError] = React.useState(null);
    const Swal = useSwalWrapper();
    const requiredEditAccess = { key: ACCESS_SECTIONS.FARMS, value: ACCESS.EDIT };
    console.log(props.group)


    const validate = () => {
        var warningMessages = [];
        var errorMessages = [];


        const formatErrorMessage = (array) => {
            var errString = '';
            array.map((a) => {
                errString = errString + a + '<br/>';
            });
            return errString;
        }
        if (errorMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(errorMessages),
                icon: 'error',
                title: 'Oops...',

            });
        } else if (warningMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(warningMessages),
                icon: 'warning',
                title: 'Oops...',
                showCancelButton: true,
                confirmButtonText: 'Yes, Continue',
                cancelButtonText: 'No, I\'ll edit it',

            }).then(result => {
                if (result.value) {
                    submit();
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                }
            });
        } else {
            submit();
        }
    }
    const updateTotal = (tot) => {
        if (tot <= props.group?.totalUnits) {
            if (tot >= 0) {
                setTotal(tot);
            }
        }
    }
    const submit = async () => {
        //setLoading(true);
        //setCompleted(true);

        //var res = await postApi('/Farms/Groups/Combine', { GroupId: props.groupA?.id, JoinId: props.groupB?.id, Date: textfieldDateFormat(date) });
        //console.log(res);
        //if (res.ok) {
        //    setCompleted(true);
        //} else {
        //    setError("Error Text");
        //}
        //setLoading(false);
        //if (props.reload) {
        //    props.reload();
        //}

    }
    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '24ch' },
                zIndex: '1005',

            }}
            noValidate
            autoComplete="off"
        >
            <Div>

                <Typography variant={"h2"} >Deploy</Typography>
                <Grid container spacing={2.5} mb={1}>

                    <Grid item xs={12} >
                        <DatePicker date={date} setDate={setDate} label="Date" disabled={completed} />
                    </Grid>

                    <Grid item xs={12} >
                        <OnShoreCard cropCols={props.cropCols} isSel={false} selectedCard={() => { }} selected={null} group={props.group} canCombine={false} opacity={0.5} ind={0} selectFunc={() => { }} combineFunc={() => { }} gradeFunc={() => { }} splitFunc={() => { }} />
                    </Grid>
                    <Grid item xs={12} >
                        <ControlledTextField noDecimal key={'tu'} title="Total Units to Deploy" state={total} setState={updateTotal} disabled={completed} number />
                    </Grid>
                    <Grid item xs={12}  >
                        <Link href={"/OysterM8?warehouseId=" + props.group.warehouseId + "&GroupId=" + props.group.id + "&units=" + total}>
                            <Button variant="contained" color="primary">
                                Deploy to Map
                            </Button>
                        </Link>
                    </Grid>
                </Grid>

            </Div>
        </Box >
    );
}
const CombineGroup = (props) => {



    const [completed, setCompleted] = React.useState(props.completed);
    const [loading, setLoading] = React.useState(false);
    const [date, setDate] = React.useState(new Date());
    const [error, setError] = React.useState(null);
    const Swal = useSwalWrapper();
    const requiredEditAccess = { key: ACCESS_SECTIONS.FARMS, value: ACCESS.EDIT };



    const validate = () => {
        var warningMessages = [];
        var errorMessages = [];
        var date = false;
        var grade = false;
        var supplier = false;
        var location = false;
        var average = false;
        var unittype = false;
        for (var i = 0; i < props.groups.length; i++) {
            for (var j = 0; j < props.groups.length; j++) {
                //check if any group has different properties to any other group
                if (i !== j) {
                    if (!location && (props.groups[i].previousLocation?.line?.lease?.id !== props.groups[j].previousLocation?.line?.lease?.id)) {
                        warningMessages.push("Are you sure you want to combine groups from different leases?");
                        location = true;
                    }
                    if (!date && (props.groups[i].shore?.date !== props.groups[j].shore?.date)) {
                        warningMessages.push("Are you sure you want to combine groups with different harvest dates?");
                        date = true;
                    }
                    if (!grade && (props.groups[i].grade?.id !== props.groups[j].grade?.id)) {
                        warningMessages.push("Are you sure you want to combine groups with different grades?");
                        grade = true;
                    }
                    if (!supplier && (props.groups[i].supplier?.id !== props.groups[j].supplier?.id)) {
                        warningMessages.push("Are you sure you want to combine groups with different suppliers?");
                        supplier = true;
                    }
                    if (!average && (props.groups[i].average !== props.groups[j].average)) {
                        warningMessages.push("Are you sure you want to combine groups with different Oysters per unit?");
                        average = true;
                    }
                    if (!unittype && (props.groups[i].unitType?.id !== props.groups[j].unitType?.id)) {
                        warningMessages.push("Are you sure you want to combine groups with different Unit Types?");
                        unittype = true;
                    }
                }

            }
        }
        const formatErrorMessage = (array) => {
            var errString = '';
            array.map((a) => {
                errString = errString + a + '<br/>';
            });
            return errString;
        }
        if (errorMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(errorMessages),
                icon: 'error',
                title: 'Oops...',

            });
        } else if (warningMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(warningMessages),
                icon: 'warning',
                title: 'Oops...',
                showCancelButton: true,
                confirmButtonText: 'Yes, Continue',
                cancelButtonText: 'No, I\'ll edit it',

            }).then(result => {
                if (result.value) {
                    submit();
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                }
            });
        } else {
            submit();
        }
    }

    const submit = async () => {
        setLoading(true);
        setCompleted(true);
        var newGrp = props.groups[0];
        var res = null;
        for (var i = 1; i < props.groups.length; i++) {
            res = await postApi('/Farms/Groups/Combine', { GroupId: newGrp.id, JoinId: props.groups[i]?.id, Date: textfieldDateFormat(date) });
            newGrp = await res.json();
        }
        if (res.ok) {
            setCompleted(true);
        } else {
            setError("Error Text");
        }
        setLoading(false);
        if (props.reload) {
            props.reload();
        }
        if (props.handleClose) {
            props.handleClose();
        }
    }
    var totalCombine = props.groups.reduce((p, c) => { return p + (c.totalUnits) }, 0);
    return (

        <Grid container spacing={2.5} mb={1} style={{ border: '3px dashed black' }} >

            <Grid item xs={12} md={6} >
                <Typography variant={"h1"} >Combine to One Group (Total Units: {(totalCombine)})</Typography>
            </Grid>
            <Grid item xs={12} md={6} >

                <DatePicker date={date} setDate={setDate} label="Date" disabled={completed} />
            </Grid>
            {props.groups?.map((g, i) => {
                var lg = 4;
                var xl = 3;
                if (props.groups.length === 2) {
                    lg = 6;
                    xl = 6;
                }
                if (props.groups.length === 3) {
                    xl = 4;
                }
                return <Grid item xs={12} sm={12} md={6} lg={lg} xl={xl} >
                    <OnShoreCard cropCols={props.cropCols} isSel={false} selectedCard={() => { }} selected={null} group={g} canCombine={false} opacity={0.5} ind={0} selectFunc={() => { }} combineFunc={() => { }} gradeFunc={() => { }} splitFunc={() => { }} />
                </Grid>
            })}

            <Grid item xs={12}  >
                <SubmitButtonDisplayer error={error} title={'Combine All'} completed={completed} loading={loading} allowed={checkAccess(props.access, requiredEditAccess)} submitFunc={validate} handleClose={props.handleClose} />
                <br />
                <br />
            </Grid>
        </Grid>

    );
}
const Combine = (props) => {



    const [completed, setCompleted] = React.useState(props.completed);
    const [loading, setLoading] = React.useState(false);
    const [date, setDate] = React.useState(new Date());
    const [error, setError] = React.useState(null);
    const Swal = useSwalWrapper();
    const requiredEditAccess = { key: ACCESS_SECTIONS.FARMS, value: ACCESS.EDIT };



    const validate = () => {
        var warningMessages = [];
        var errorMessages = [];
        if (props.groupA.supplier?.id !== props.groupB.supplier?.id) {
            warningMessages.push("Are you sure you want to combine two different suppliers?");
        }
        if (props.groupA.grade?.id !== props.groupB.grade?.id) {
            warningMessages.push("Are you sure you want to combine two different grades?");
        }
        console.log(props.groupA.shore?.date);
        if (props.groupA.shore?.date !== props.groupB.shore?.date) {
            warningMessages.push("Are you sure you want to combine groups with different harvest dates?");
        }
        console.log(props.groupA);
        if (props.groupA.previousLocation?.line?.lease?.id !== props.groupB.previousLocation?.line?.lease?.id) {
            warningMessages.push("Are you sure you want to combine groups from different leases?");
        }
        const formatErrorMessage = (array) => {
            var errString = '';
            array.map((a) => {
                errString = errString + a + '<br/>';
            });
            return errString;
        }
        if (errorMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(errorMessages),
                icon: 'error',
                title: 'Oops...',

            });
        } else if (warningMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(warningMessages),
                icon: 'warning',
                title: 'Oops...',
                showCancelButton: true,
                confirmButtonText: 'Yes, Continue',
                cancelButtonText: 'No, I\'ll edit it',

            }).then(result => {
                if (result.value) {
                    submit();
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                }
            });
        } else {
            submit();
        }
    }

    const submit = async () => {
        setLoading(true);
        setCompleted(true);

        var res = await postApi('/Farms/Groups/Combine', { GroupId: props.groupA?.id, JoinId: props.groupB?.id, Date: textfieldDateFormat(date) });
        console.log(res);
        if (res.ok) {
            setCompleted(true);
        } else {
            setError("Error Text");
        }
        setLoading(false);
        if (props.reload) {
            props.reload();
        }

    }
    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '24ch' },
                zIndex: '1005',

            }}
            noValidate
            autoComplete="off"
        >
            <Div>

                <Typography variant={"h2"} >Combine</Typography>
                <Grid container spacing={2.5} mb={1}>

                    <Grid item xs={12} >

                        <DatePicker date={date} setDate={setDate} label="Date" disabled={completed} />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} >
                        <OnShoreCard cropCols={props.cropCols} isSel={false} selectedCard={() => { }} selected={null} group={props.groupA} canCombine={false} opacity={0.5} ind={0} selectFunc={() => { }} combineFunc={() => { }} gradeFunc={() => { }} splitFunc={() => { }} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <OnShoreCard cropCols={props.cropCols} isSel={false} selectedCard={() => { }} selected={null} group={props.groupB} canCombine={false} opacity={0.5} ind={0} selectFunc={() => { }} combineFunc={() => { }} gradeFunc={() => { }} splitFunc={() => { }} />
                    </Grid>
                    <Grid item xs={12}  >
                        <SubmitButtonDisplayer error={error} title={'Submit'} completed={completed} loading={loading} allowed={checkAccess(props.access, requiredEditAccess)} submitFunc={validate} handleClose={props.handleClose} />
                    </Grid>
                </Grid>

            </Div>
        </Box >
    );
}

const Split = (props) => {

    const [completed, setCompleted] = React.useState(props.completed);
    const [loading, setLoading] = React.useState(false);
    const [date, setDate] = React.useState(new Date());
    const [total, setTotal] = React.useState(null);
    const [error, setError] = React.useState(null);
    const Swal = useSwalWrapper();
    const requiredEditAccess = { key: ACCESS_SECTIONS.FARMS, value: ACCESS.EDIT };



    const validate = () => {
        var warningMessages = [];
        var errorMessages = [];
        if (Number(total) > Number(props.group.totalUnits)) {
            errorMessages.push("Split Total is greater than Total Units in Group");
        }
        if (Number(total) === Number(props.group.totalUnits)) {
            errorMessages.push("Split Total is equal to Total Units in Group");
        }
        if (!total || (total <= 0)) {
            errorMessages.push("Total can't be less than or equal to 0");

        }
        const formatErrorMessage = (array) => {
            var errString = '';
            array.map((a) => {
                errString = errString + a + '<br/>';
            });
            return errString;
        }
        if (errorMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(errorMessages),
                icon: 'error',
                title: 'Oops...',

            });
        } else if (warningMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(warningMessages),
                icon: 'warning',
                title: 'Oops...',
                showCancelButton: true,
                confirmButtonText: 'Yes, Continue',
                cancelButtonText: 'No, I\'ll edit it',

            }).then(result => {
                if (result.value) {
                    submit();
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                }
            });
        } else {
            submit();
        }
    }

    const submit = async () => {
        setLoading(true);
        setCompleted(true);
        var res = await postApi('/Farms/Groups/Split', { GroupId: props.group?.id, SplitUnits: total, Date: textfieldDateFormat(date) })
        if (res.ok) {
            setCompleted(true);
        } else {
            setError("Error Text");
        }
        setLoading(false);
        if (props.reload) {
            props.reload();
        }

    }
    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '24ch' },
                zIndex: '1005',

            }}
            noValidate
            autoComplete="off"
        >
            <Div>

                <Typography variant={"h2"} >Split</Typography>
                <Grid container spacing={2.5} mb={1}>

                    <Grid item xs={12} >
                        <DatePicker date={date} setDate={setDate} label="Date" disabled={completed} />
                    </Grid>
                    <Grid item xs={12} sm={12} >
                        <OnShoreCard cropCols={props.cropCols} isSel={false} selectedCard={() => { }} selected={null} group={props.group} canCombine={false} opacity={0.5} ind={0} selectFunc={() => { }} combineFunc={() => { }} gradeFunc={() => { }} splitFunc={() => { }} />
                    </Grid>
                    <Grid item xs={12} sm={12} >
                        <ControlledTextField noDecimal key={'tu'} title="Total Units Split" state={total} setState={setTotal} disabled={completed} number />
                    </Grid>
                    <Grid item xs={12}  >
                        <SubmitButtonDisplayer error={error} title={'Submit'} completed={completed} loading={loading} allowed={checkAccess(props.access, requiredEditAccess)} submitFunc={validate} handleClose={props.handleClose} />
                    </Grid>
                </Grid>

            </Div>
        </Box >
    );
}

const Grade = (props) => {
    var emptyLine = { grade: null, total: null, unitType: null, average: null };
    const [completed, setCompleted] = React.useState(props.completed);
    const [loading, setLoading] = React.useState(false);
    const [date, setDate] = React.useState(new Date());
    const [grades, setGrades] = React.useState(null);
    const [unitTypes, setUnitTypes] = React.useState(null);
    const [outGrades, setOutGrades] = React.useState([{ ...emptyLine }]);
    const [gradeComplete, setGradeComplete] = React.useState(true);
    const [error, setError] = React.useState(null);
    const Swal = useSwalWrapper();
    const requiredEditAccess = { key: ACCESS_SECTIONS.FARMS, value: ACCESS.EDIT };

    const filterGrades = (grds) => {
        var filteredGrades = grds.filter((g) => {
            return props.group?.grade?.speciesId === g.speciesId;
        })
        setGrades(filteredGrades);
    }

    React.useEffect(() => {
        //get existing graded children
        getAPIDataParams('/Farms/Lookups/Grades', filterGrades);
        getAPIDataParams('/Farms/Lookups/UnitTypes', setUnitTypes);

    }, [])
    console.log(grades)
    const setUnitType = (ind) => (ut) => {
        var cpyOG = [...outGrades];
        cpyOG[ind].unitType = { ...ut };
        setOutGrades(cpyOG);
    }
    const setAverage = (ind) => (tot) => {
        var cpyOG = [...outGrades];
        cpyOG[ind].average = tot;
        setOutGrades(cpyOG);
    }
    const setAverageDoz = (ind) => (tot) => {
        var cpyOG = [...outGrades];
        cpyOG[ind].average = tot ? (tot * 12) : tot;
        setOutGrades(cpyOG);
    }
    const setGrade = (ind) => (grd) => {
        var cpyOG = [...outGrades];
        cpyOG[ind].grade = { ...grd };
        setOutGrades(cpyOG);
    }
    const setTotal = (ind) => (tot) => {
        var cpyOG = [...outGrades];
        cpyOG[ind].total = tot;
        setOutGrades(cpyOG);
    }
    const validate = (forceComplete) => {
        var warningMessages = [];
        var errorMessages = [];
        var TU = outGrades.reduce((p, c) => { return p + Number(c.total) }, 0);
        var TO = outGrades.reduce((p, c) => { return p + (Number(c.total) * Number(c.average)) }, 0);
        var STO = Number(props.group?.average) * Number(props.group?.totalUnits);
        var isSlats = props.group?.grade?.description.includes('Slats');
        var Mort = STO - TO;
        var MortPerc = (100 * (Mort / STO));
        var children = (props.group?.children && (props.group?.children.length > 0)) ? props.group.children : null;
        if (children) {
            TU = TU + children.reduce((p, c) => { return p + Number(c.totalUnits) }, 0);
            TO = TO + children.reduce((p, c) => { return p + (Number(c.totalUnits) * Number(c.average)) }, 0);
            Mort = STO - TO;
            MortPerc = (100 * (Mort / STO));
        }
        if ((outGrades.length <= 0)&&!forceComplete) {
            errorMessages.push("You must select at least one grade");
        }
        if (!forceComplete) {
            outGrades.map((og) => {
                if (og.total <= 0) {
                    errorMessages.push("Units can't be less than or equal to 0");
                }
                if (og.average <= 0) {
                    errorMessages.push("Average can't be less than or equal to 0");
                }
                if (!og.grade) {
                    errorMessages.push("You must select a grade for each line");
                }
                if (!og.unitType) {
                    errorMessages.push("You must select a Unit Type for each line");
                }
            })
        }

        if (gradeComplete) {
            if (!isSlats && (MortPerc < 0)) {
                warningMessages.push("There are more Oysters coming out then went in. Is this correct?");
            }
            if (MortPerc > 20) {
                warningMessages.push("Variance is greater than 20%. Is this correct?");
            }
        }
        const formatErrorMessage = (array) => {
            var errString = '';
            array.map((a) => {
                errString = errString + a + '<br/>';
            });
            return errString;
        }
        if (errorMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(errorMessages),
                icon: 'error',
                title: 'Oops...',

            });
        } else if (warningMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(warningMessages),
                icon: 'warning',
                title: 'Oops...',
                showCancelButton: true,
                confirmButtonText: 'Yes, Continue',
                cancelButtonText: 'No, I\'ll edit it',

            }).then(result => {
                if (result.value) {
                    submit(forceComplete);
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                }
            });
        } else {
            submit(forceComplete);
        }
    }
    const addLine = () => {
        var cpyOG = [...outGrades];
        cpyOG.push({ ...emptyLine });
        setOutGrades(cpyOG);
    }
    const removeLine = () => {
        var cpyOG = [...outGrades];
        cpyOG.pop();
        setOutGrades(cpyOG);
    }
    const submit = async (forceComplete) => {
        setLoading(true);
        setCompleted(true);
        var prmArr = [];
        console.log(forceComplete);
        if (!forceComplete) {
            for (var i = 0; i < outGrades.length; i++) {
                prmArr.push(postApi('/Farms/Groups/Adjustment', { GroupId: props.group?.id, UnitTypeId: outGrades[i].unitType.id, GradeId: outGrades[i].grade.id, Average: outGrades[i].average, TotalUnits: outGrades[i].total, Date: textfieldDateFormat(date), Complete: gradeComplete }));
            }
        } else {
            prmArr.push(postApi('/Farms/Groups/CompleteGroup', { GroupId: props.group?.id}));

        }
        var outs = await Promise.all(prmArr);
        for (var i = 0; i < outs.length; i++) {
            if (!outs[i].ok) {
                setError("Error Text");
            }
        }

        setLoading(false);
        if (props.reload) {
            props.reload();
        }

    }
    var TU = outGrades.reduce((p, c) => { return p + Number(c.total) }, 0);
    var TO = outGrades.reduce((p, c) => { return p + (Number(c.total) * Number(c.average)) }, 0);
    var STO = Number(props.group?.average) * Number(props.group?.totalUnits);
    var Mort = STO - TO;
    var MortPerc = (100 * (Mort / STO));
    var children = (props.group?.children && (props.group?.children.length > 0)) ? props.group.children : null;
    if (children) {
        TU = TU + children.reduce((p, c) => { return p + Number(c.totalUnits) }, 0);
        TO = TO + children.reduce((p, c) => { return p + (Number(c.totalUnits) * Number(c.average)) }, 0);
        Mort = STO - TO;
        MortPerc = (100 * (Mort / STO));
    }
    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '24ch' },
                zIndex: '1005',

            }}
            noValidate
            autoComplete="off"
        >
            <Div>

                <Typography variant={"h2"} >Grade</Typography>
                <Grid container spacing={2.5} mb={1}>

                    <Grid item xs={12} >
                        <DatePicker date={date} setDate={setDate} label="Date" disabled={completed} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} >
                        <OnShoreCard cropCols={props.cropCols} isSel={false} selectedCard={() => { }} selected={null} group={props.group} canCombine={false} opacity={0.5} ind={0} selectFunc={() => { }} combineFunc={() => { }} gradeFunc={() => { }} splitFunc={() => { }} />
                        <br />
                        <Typography variant={"h4"} >Starting Total Oysters: {displayDozFormat(STO, true)}</Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} >
                        {children ? children.map((child, ind) => {
                            console.log(child)
                            return <Grid container spacing={2.5} mb={1} style={{ borderBottom: '1px solid black' }}>
                                <Grid item xs={12} sm={12} md={6} >
                                    <Typography variant={"h5"} >Grade: {child.grade?.description}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} >
                                    <Typography variant={"h5"} >Total Units: {child.totalUnits}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} >
                                    <Typography variant={"h5"} >Average: {displayDozFormat(child.average)}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} >
                                    <Typography variant={"h5"} >Unit Type: {child.unitType?.description}</Typography>
                                </Grid>
                                <div style={{ width: '100%', textAlign: 'right' }} >{displayDozFormat(child.totalUnits * child.average)}</div>

                            </Grid>
                        }) : null}
                        {outGrades.map((grd, ind) => {

                            return <Grid container spacing={2.5} mb={1} style={{ borderBottom: '1px solid black' }}>
                                <Grid item xs={12} sm={12} md={6} >
                                    <SelectTextField key={'gd' + ind} title="Grade" state={grd.grade} nameFunc={(gd) => { return gd.description }} list={grades} setState={setGrade(ind)} disabled={completed} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} >
                                    <ControlledTextField noDecimal key={'tu' + ind} title="No. Units" state={grd.total} setState={setTotal(ind)} disabled={completed} number />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} >
                                    <Grid container spacing={2.5} mb={1} >
                                        <Grid item xs={6} >
                                            <ControlledTextField noDecimal width={'auto'} key={'tup' + ind} title="Pcs/Unit" state={grd.average} setState={setAverage(ind)} disabled={completed} number />
                                        </Grid>

                                        <Grid item xs={6} >
                                            <ControlledTextField noDecimal width={'auto'} key={'tud' + ind} title="Doz/Unit" state={grd.average ? (grd.average / 12).toFixed(0) : grd.average} setState={setAverageDoz(ind)} disabled={completed} number />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} >
                                    <SelectTextField key={'gd' + ind} title="Unit Type" state={grd.unitType} nameFunc={(gd) => { return gd.description }} list={unitTypes} setState={setUnitType(ind)} disabled={completed} />
                                </Grid>
                                <div style={{ width: '100%', textAlign: 'right' }} >{displayDozFormat(grd.total * grd.average)}</div>
                            </Grid>
                        })}
                        <Stack direction="row" spacing={1}>
                            <IconButton aria-label="add" onClick={addLine} disabled={completed}>
                                <AddIcon />
                            </IconButton>
                            <IconButton aria-label="remove" onClick={removeLine} disabled={completed}>
                                <RemoveIcon />
                            </IconButton>
                        </Stack>
                        <Grid container spacing={0.5} mb={1}>
                            <Grid item xs={12} sm={12} >
                                <Typography variant={"h4"} >Total Units: {TU}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} >
                                <Typography variant={"h4"} >Total Oysters: {displayDozFormat(TO, true)}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} >
                                <Typography variant={"h4"} >Variance: {displayDozFormat(Mort, true) + ' (' + MortPerc.toFixed(0) + '%)'}</Typography>
                            </Grid>


                        </Grid>
                    </Grid>
                    <Grid item xs={12}  >
                        <Grid container spacing={0.5} mb={1}>
                            <Grid item xs={12} >
                                <FormControlLabel
                                    label={"Grading Complete"}
                                    control={
                                        <Checkbox
                                            checked={gradeComplete}
                                            onChange={(event) => { setGradeComplete(event.target.checked) }}
                                        />
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} >
                                {children && !((outGrades?.length > 0) && (outGrades?.[0]?.total > 0)) && gradeComplete ? <SubmitButtonDisplayer error={error} title={'Complete Group'} completed={completed} loading={loading} allowed={checkAccess(props.access, requiredEditAccess)} submitFunc={() => { validate(true) }} handleClose={props.handleClose} /> 
                                    : <SubmitButtonDisplayer error={error} title={'Submit'} completed={completed} loading={loading} allowed={checkAccess(props.access, requiredEditAccess)} submitFunc={() => { validate(false) }} handleClose={props.handleClose} />}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Div>
        </Box >
    );
}

const Transfer = (props) => {



    const [completed, setCompleted] = React.useState(props.completed);
    const [compCode, setCompCode] = React.useState(null);
    const [destination, setDestination] = React.useState(null);
    const [source, setSource] = React.useState(null);
    const [comment, setComment] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [FtoW, setFtoW] = React.useState(true);
    const [date, setDate] = React.useState(new Date());
    const [error, setError] = React.useState(null);
    const [printBin, setPrintBin] = React.useState(true);
    const [printBag, setPrintBag] = React.useState(true);
    const Swal = useSwalWrapper();
    const requiredEditAccess = { key: ACCESS_SECTIONS.FARMS, value: ACCESS.EDIT };
    const isBatemansFarm = props.warehouseId === 6;


    const validate = () => {
        var warningMessages = [];
        var errorMessages = [];
        if (!destination) {
            errorMessages.push('You must select a Destination');
        }
        if (!compCode) {
            errorMessages.push('You must enter a ' + (FtoW ? 'PRN' : 'IVR'));
        }
        if(isBatemansFarm){
            if (!source) {
                errorMessages.push('You must select a Source');
            }
        }
        // check compcode is a number
        if (compCode && isNaN(Number(compCode)) && FtoW) {
            errorMessages.push('You must enter a valid PRN');
        }
        if (compCode && compCode.length>10) {
            errorMessages.push('Your '+(FtoW ? 'PRN' : 'IVR')+ ' is to long' );
        }
        if (!props.groups || (props.groups.length <= 0)) {
            errorMessages.push('You must select a group to transfer');
        }
        var SourceWarehouseId = props.warehouseId;
        var DestinationWarehouseId = FtoW ? destination.id : destination.warehouseId;
        if (SourceWarehouseId === DestinationWarehouseId) {
            errorMessages.push('You cannot transfer to the same warehouse');
        }


        const formatErrorMessage = (array) => {
            var errString = '';
            array.map((a) => {
                errString = errString + a + '<br/>';
            });
            return errString;
        }
        if (errorMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(errorMessages),
                icon: 'error',
                title: 'Oops...',

            });
        } else if (warningMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(warningMessages),
                icon: 'warning',
                title: 'Oops...',
                showCancelButton: true,
                confirmButtonText: 'Yes, Continue',
                cancelButtonText: 'No, I\'ll edit it',

            }).then(result => {
                if (result.value) {
                    submit();
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                }
            });
        } else {
            submit();
        }
    }
    const updateDestinationMode = (checked) => {
        setFtoW(checked);
        setDestination(null);
    }
    const submit = async () => {
        setLoading(true);
        setCompleted(true);
        //props.warehouseId;
        var firstCreate = await postApi('/Transfers/CreateFromFarm', { SourceWarehouseId: (isBatemansFarm?source?.warehouseId:props.warehouseId), DestinationWarehouseId: FtoW ? destination.id : destination.warehouseId, GroupId: props.groups[0]?.id, ComplianceCode: compCode, Comment: comment });
        if (firstCreate.ok) {
            const transfer = await firstCreate.json();
            console.log(transfer);
            //loop through groups starting at 1 and create transfers
            var promArr = [];
            var results = [transfer];
            var allOk = true;
            for (var i = 1; i < props.groups.length; i++) {
                var currOp = await postApi('/Transfers/AddGroup', { TransferId: transfer.id, GroupId: props.groups[i]?.id });
                if (currOp.ok) {

                    results.push(await currOp.json());
                } else {
                    allOk = false;
                }
            }
            console.log(results);

            if (allOk) {
                var lastgroup = results[results.length - 1];
                await OTRPdf(lastgroup);
                await OTRLabels(lastgroup,printBin,printBag);
                setCompleted(true);
            } else {
                setError("Failed to add all groups to transfer");
            }
        } else {
            setError("Failed to Create Transfer");
        }
        setLoading(false);
        if (props.reload) {
            props.reload();
        }

    }
    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '24ch' },
                zIndex: '1005',

            }}
            noValidate
            autoComplete="off"
        >
            <Div>

                <Typography variant={"h2"} >Transfer</Typography>
                <Grid container spacing={2.5} mb={1}>

                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>

                        <DatePicker date={date} setDate={setDate} label="Date" disabled={completed} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4} >

                        <Typography style={{ display: 'inline' }}>To Farm</Typography>
                        <Switch checked={FtoW} onChange={(event) => updateDestinationMode(event.target.checked)} name="header-fixed" />
                        <Typography style={{ display: 'inline' }}>To Factory</Typography>
                    </Grid>
                    <Grid item xs={12} >
                    </Grid>
                    {isBatemansFarm?<Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        {isBatemansFarm ?
                            <SelectAOCFarmWarehouse warehouseId={props.groups[0]?.previousLocation?.line?.lease?.warehouseId} showAll normal setState={setSource} state={source} access={props.access} disabled={completed} title="Source" />:null}
                    </Grid>:null}
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        {FtoW ?
                            <SelectWarehouse cleanDisp useCache state={destination} setState={setDestination} disabled={completed} title="Destination" /> :
                            <SelectAOCFarmWarehouse showAll normal setState={setDestination} state={destination} access={props.access} disabled={completed} title="Destination" />}
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <ControlledTextField title={FtoW ? "PRN" : "IVR"} state={compCode} setState={setCompCode} disabled={completed} />
                    </Grid>
                    <Grid item xs={12} >
                    </Grid>
                    {props.groups ?
                        props.groups.map((grp) => {
                            return <Grid item xs={12} sm={12} md={6} lg={4} >
                                <OnShoreCard cropCols={props.cropCols} isSel={false} selectedCard={() => { }} selected={null} group={grp} canCombine={false} opacity={0.5} ind={0} selectFunc={() => { }} combineFunc={() => { }} gradeFunc={() => { }} splitFunc={() => { }} />
                            </Grid>
                        })
                        : null}
                    <Grid item xs={12}  >
                        {props.groups.map((grp) => {

                            return <Typography variant={"h5"} mb={.5}>{grp?.grade?.description + ': ' + displayDozFormat(grp?.totalUnits * grp?.average, true)}</Typography>
                        })}
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <TextField
                            disabled={completed}
                            id="outlined-multiline-static"
                            label="Comment"
                            value={comment}
                            onChange={(event) => { setComment(event.target.value) }}
                            multiline
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{ width: '100%' }}
                            rows={3} />
                        <FormControlLabel
                            label={"Download Bin Labels"}
                            control={
                                <Checkbox
                                    checked={printBin}
                                    onChange={(event) => { setPrintBin(event.target.checked) }}
                                />
                            }
                        />
                        <FormControlLabel
                            label={"Download Bag Labels"}
                            control={
                                <Checkbox
                                    checked={printBag}
                                    onChange={(event) => { setPrintBag(event.target.checked) }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12}  >
                        <SubmitButtonDisplayer error={error} title={'Submit'} completed={completed} loading={loading} allowed={checkAccess(props.access, requiredEditAccess)} submitFunc={validate} handleClose={props.handleClose} />
                    </Grid>
                </Grid>

            </Div>
        </Box >
    );
}

const NoteEdit = (props) => {
    const [completed, setCompleted] = React.useState(props.completed);
    const [loading, setLoading] = React.useState(false);
    const [note, setNote] = React.useState(props.group.note);
    const [error, setError] = React.useState(null);
    const Swal = useSwalWrapper();
    const requiredEditAccess = { key: ACCESS_SECTIONS.FARMS, value: ACCESS.EDIT };



    const validate = () => {
        var warningMessages = [];
        var errorMessages = [];

        const formatErrorMessage = (array) => {
            var errString = '';
            array.map((a) => {
                errString = errString + a + '<br/>';
            });
            return errString;
        }
        if (errorMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(errorMessages),
                icon: 'error',
                title: 'Oops...',

            });
        } else if (warningMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(warningMessages),
                icon: 'warning',
                title: 'Oops...',
                showCancelButton: true,
                confirmButtonText: 'Yes, Continue',
                cancelButtonText: 'No, I\'ll edit it',

            }).then(result => {
                if (result.value) {
                    submit();
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                }
            });
        } else {
            submit();
        }
    }

    const submit = async () => {
        setLoading(true);
        setCompleted(true);

        var res = await postApi('/Farms/Groups/AddNote', { GroupId: props.group?.id, Note: note });
        console.log(res);
        if (res.ok) {
            setCompleted(true);
        } else {
            setError("Error Text");
        }
        setLoading(false);
        if (props.reload) {
            props.reload();
        }

    }
    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '24ch' },
                zIndex: '1005',

            }}
            noValidate
            autoComplete="off"
        >
            <Div>

                <Typography variant={"h2"} >Combine</Typography>
                <Grid container spacing={2.5} mb={1}>

                    <Grid item xs={12} sm={12} md={6} >
                        <OnShoreCard cropCols={props.cropCols} isSel={false} selectedCard={() => { }} selected={null} group={props.group} canCombine={false} opacity={0.5} ind={0} selectFunc={() => { }} combineFunc={() => { }} gradeFunc={() => { }} splitFunc={() => { }} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={5}>
                        <TextField
                            disabled={completed}
                            id="outlined-multiline-static"
                            label="Note"
                            value={note}
                            onChange={(event) => { setNote(event.target.value) }}
                            multiline
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{ width: '100%' }}
                            rows={10} />
                    </Grid>
                    <Grid item xs={12}  >
                        <SubmitButtonDisplayer error={error} title={'Submit'} completed={completed} loading={loading} allowed={checkAccess(props.access, requiredEditAccess)} submitFunc={validate} handleClose={props.handleClose} />
                    </Grid>
                </Grid>

            </Div>
        </Box >
    );
}

const AttachPhoto = (props) => {
    const [completed, setCompleted] = React.useState(props.completed);
    const [loading, setLoading] = React.useState(false);
    const [loadingDel, setLoadingDel] = React.useState(false);
    const [note, setNote] = React.useState(props.group.note);
    const [error, setError] = React.useState(null);
    const [images, setImages] = React.useState(false);
    const [haveImage, setHaveImage] = React.useState(false);
    const [showCamera, setShowCamera] = React.useState(false);
    const [imageUrl, setImageUrl] = React.useState(null);
    const [files, setFiles] = React.useState([]);
    const [reload, setReload] = React.useState(false);
    const Swal = useSwalWrapper();
    const requiredEditAccess = { key: ACCESS_SECTIONS.FARMS, value: ACCESS.EDIT };
    const uri = process.env.REACT_APP_BASE_URL;

    React.useEffect(() => {
        getAPIDataParams('/Farms/GroupImages/Get', setImages, { GroupId: props.group.id }).then((res) => {
            setLoadingDel(false);
        });
    }, [reload]);

    const validate = () => {
        var warningMessages = [];
        var errorMessages = [];

        const formatErrorMessage = (array) => {
            var errString = '';
            array.map((a) => {
                errString = errString + a + '<br/>';
            });
            return errString;
        }
        if (errorMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(errorMessages),
                icon: 'error',
                title: 'Oops...',

            });
        } else if (warningMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(warningMessages),
                icon: 'warning',
                title: 'Oops...',
                showCancelButton: true,
                confirmButtonText: 'Yes, Continue',
                cancelButtonText: 'No, I\'ll edit it',

            }).then(result => {
                if (result.value) {
                    submit();
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                }
            });
        } else {
            submit();
        }
    }

    const submit = async () => {
        setLoading(true);
        setCompleted(true);


        var formdata = new FormData();
        if (files[0]) {
            formdata.append('uploadfile', files[0], 'uploadfile');
        } else if (imageUrl) {
            var blob = dataURItoBlob(imageUrl);
            formdata.append("uploadfile", blob);
        }
        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };
        var response = await adalApiFetch(fetch, uri + '/Farms/GroupImages/Add?GroupId=' + props.group.id, requestOptions);
        console.log(response);
        if (response.ok) {
            setCompleted(true);
        } else {
            setError("Error Text");
        }
        setLoading(false);
        if (props.reload) {
            props.reload();
        }
        setReload(!reload);

    }
    const EditImage = () => {
        if (haveImage) {
            return <div><div>Click to Remove</div><img src={imageUrl} width="25%" onClick={() => { setHaveImage(!haveImage) }} /></div>

        } else {
            return <DropZone files={files} setFiles={setFiles} />
        }
    }
    const handleTakePhoto = (dataUri) => {
        setImageUrl(dataUri);
        setHaveImage(true);
    }
    const handleImageDelete = id => {
        setLoadingDel(true);
        postApi('/Farms/GroupImages/Delete', { GroupImageId: id }).then((result) => {
            setReload(!reload);
        });
    };

    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '24ch' },
                zIndex: '1005',

            }}
            noValidate
            autoComplete="off"
        >
            <Div>

                <Typography variant={"h2"} >Add Photo</Typography>
                <Grid container spacing={2.5} mb={1}>

                    <Grid item xs={12} sm={12} md={6} >
                        <OnShoreCard openHistoryModal={props.openHistoryModal} cropCols={props.cropCols} isSel={false} selectedCard={() => { }} selected={null} group={props.group} canCombine={false} opacity={0.5} ind={0} selectFunc={() => { }} combineFunc={() => { }} gradeFunc={() => { }} splitFunc={() => { }} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={5}>
                        {!completed ? <React.Fragment><Button onClick={() => { setShowCamera(!showCamera); }} >{showCamera ? "Hide Camera" : "Click here to use your Camera"}</Button>
                            {showCamera ? <Camera
                                onTakePhoto={(dataUri) => { handleTakePhoto(dataUri); }}
                                isMaxResolution={true}
                            /> : null}
                            <EditImage /></React.Fragment> : null}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Button onClick={props.openHistoryModal} >Click here to show history with images</Button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <SubmitButtonDisplayer error={error} title={'Submit'} completed={completed} loading={loading} allowed={checkAccess(props.access, requiredEditAccess)} submitFunc={validate} handleClose={props.handleClose} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} >
                        {images ? images.map((image, i) => {
                            return <div className="image" key={i} style={{ position: 'relative' }} ><img alt='oysters' src={image.imageURL} />
                                <IconButton style={{
                                    backgroundColor: 'black',
                                    padding: '10px',
                                    color: 'white',
                                    cursor: 'pointer',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0
                                }} aria-label="delete" onClick={() => handleImageDelete(image.id)}>
                                    {loadingDel ? <CircularProgress /> : <DeleteForeverIcon />}
                                </IconButton>


                            </div>
                        }) : null}
                    </Grid>

                </Grid>

            </Div>
        </Box >
    );
}